// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --color-black: #171a21;
    --color-white: #fef5ef;
    
    --color-pink: #c523ff;
    --color-soft-pink: #e7a5ff;
    --color-white-pink: #e7ccf0;
    --color-light-grey-pink: #baa5c2;
    --color-grey-pink: #695d6d;

    --color-purple: #6e49ff;
    --color-soft-purple: #b4a0ff;
    --color-white-purple: #cec5f3;
    --color-light-grey-purple: #b5add6;
    --color-grey-purple: #766d99;
    --color-dark-grey-purple: #2b2935;
    
    --color-blue: #2c65ff;
    --color-soft-blue: #9eb8ff;
    --color-white-blue: #c5d4ff;
    --color-light-grey-blue: #a4b4dd;
    --color-grey-blue: #7887af;
    --color-dark-grey-blue: #353b4e;
  }
  `, "",{"version":3,"sources":["webpack://./src/settings.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;;IAEtB,qBAAqB;IACrB,0BAA0B;IAC1B,2BAA2B;IAC3B,gCAAgC;IAChC,0BAA0B;;IAE1B,uBAAuB;IACvB,4BAA4B;IAC5B,6BAA6B;IAC7B,kCAAkC;IAClC,4BAA4B;IAC5B,iCAAiC;;IAEjC,qBAAqB;IACrB,0BAA0B;IAC1B,2BAA2B;IAC3B,gCAAgC;IAChC,0BAA0B;IAC1B,+BAA+B;EACjC","sourcesContent":[":root {\n    --color-black: #171a21;\n    --color-white: #fef5ef;\n    \n    --color-pink: #c523ff;\n    --color-soft-pink: #e7a5ff;\n    --color-white-pink: #e7ccf0;\n    --color-light-grey-pink: #baa5c2;\n    --color-grey-pink: #695d6d;\n\n    --color-purple: #6e49ff;\n    --color-soft-purple: #b4a0ff;\n    --color-white-purple: #cec5f3;\n    --color-light-grey-purple: #b5add6;\n    --color-grey-purple: #766d99;\n    --color-dark-grey-purple: #2b2935;\n    \n    --color-blue: #2c65ff;\n    --color-soft-blue: #9eb8ff;\n    --color-white-blue: #c5d4ff;\n    --color-light-grey-blue: #a4b4dd;\n    --color-grey-blue: #7887af;\n    --color-dark-grey-blue: #353b4e;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
