// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./settings.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{
  overscroll-behavior: none;
}
body > #root > div {
  height: 100vh; 
  overscroll-behavior: none;
  color: var(--color-soft-pink);
}
.App {
  text-align: center;
  width: 100%;
}

iframe{
  border: none
}

a{
  text-decoration: none;
}
a:link {
  color: var(--color-soft-blue);
  
}
a:visited {
  color: var(--color-soft-purple);
}
a:hover{
  text-decoration: underline;
}

.resizeHandle[direction=row]:hover{
  cursor:row-resize
}
.resizeHandle[direction=col]:hover{
  cursor:col-resize
}
.frameButton:hover{
  cursor:pointer
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(90deg);}
  50% {transform: rotate(180deg);}
  75% {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
}

.genericLoader{
  animation-name: spin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;AACF;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,6BAA6B;;AAE/B;AACA;EACE,+BAA+B;AACjC;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;;AAEA;EACE,IAAI,uBAAuB,CAAC;EAC5B,KAAK,wBAAwB,CAAC;EAC9B,KAAK,yBAAyB,CAAC;EAC/B,KAAK,yBAAyB,CAAC;EAC/B,MAAM,yBAAyB,CAAC;AAClC;;AAEA;EACE,oBAAoB;EACpB,wBAAwB;EACxB,mCAAmC;EACnC,iCAAiC;AACnC","sourcesContent":["@import './settings.css';\n\n\nhtml{\n  overscroll-behavior: none;\n}\nbody > #root > div {\n  height: 100vh; \n  overscroll-behavior: none;\n  color: var(--color-soft-pink);\n}\n.App {\n  text-align: center;\n  width: 100%;\n}\n\niframe{\n  border: none\n}\n\na{\n  text-decoration: none;\n}\na:link {\n  color: var(--color-soft-blue);\n  \n}\na:visited {\n  color: var(--color-soft-purple);\n}\na:hover{\n  text-decoration: underline;\n}\n\n.resizeHandle[direction=row]:hover{\n  cursor:row-resize\n}\n.resizeHandle[direction=col]:hover{\n  cursor:col-resize\n}\n.frameButton:hover{\n  cursor:pointer\n}\n\n@keyframes spin {\n  0% {transform: rotate(0deg);}\n  25% {transform: rotate(90deg);}\n  50% {transform: rotate(180deg);}\n  75% {transform: rotate(270deg);}\n  100% {transform: rotate(360deg);}\n}\n\n.genericLoader{\n  animation-name: spin;\n  animation-duration: 1.5s;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
